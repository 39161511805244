import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPressRelease = createAsyncThunk(
  "pressRelease/fetchPressRelease",
  async ({ category, search, page = 1, limit = 10 }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/press-release/getByCategory/${category}`,
        {
          params: {
            search,
            page,
            limit,
            deleted: false,
          },
        }
      );
      return {
        ...response.data,
        category,
      };
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Failed to fetch press releases"
      );
    }
  }
);


export const fetchPressReleasesByUser = createAsyncThunk(
  "pressRelease/fetchPressReleasesByUser",
  async ({ userId, status }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/press-release/user/${userId}`,
        {
          params: {
            status,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(
        error.response?.data?.message ||
          "Failed to fetch press releases by user"
      );
    }
  }
);

export const deletePressRelease = createAsyncThunk(
  "pressReleases/delete",
  async (postId, { rejectWithValue }) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/press-release/user/${postId}`
      );
      return postId; 
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const pressReleaseFetchSlice = createSlice({
  name: "pressRelease",
  initialState: {
    pressReleaseList: [],
    total: 0,
    page: 1,
    totalPages: 1,
    pressReleaseStatus: "idle",
    statusByCategory: {},
    statusByUser: "idle",
    error: null,
    userPressReleases: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPressRelease.pending, (state, action) => {
        const { category } = action.meta.arg;
        state.pressReleaseStatus = "loading";
        state.statusByCategory[category] = "loading";
      })
      .addCase(fetchPressRelease.fulfilled, (state, action) => {
        const { category } = action.payload;
        state.pressReleaseStatus = "succeeded";
        state.statusByCategory[category] = "succeeded";

        if (state.pressReleaseList.some((item) => item.category === category)) {
          action.payload.pressRelease.forEach((newItem) => {
            if (
              !state.pressReleaseList.some(
                (existingItem) => existingItem.slug === newItem.slug
              )
            ) {
              state.pressReleaseList.push(newItem);
            }
          });
        } else {
          state.pressReleaseList = action.payload.pressRelease;
        }
        state.total = action.payload.total;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchPressRelease.rejected, (state, action) => {
        const { category } = action.meta.arg;
        state.pressReleaseStatus = "failed";
        state.statusByCategory[category] = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchPressReleasesByUser.pending, (state) => {
        state.statusByUser = "loading";
      })
      .addCase(fetchPressReleasesByUser.fulfilled, (state, action) => {
        state.statusByUser = "succeeded";
        state.userPressReleases = action.payload;
      })
      .addCase(fetchPressReleasesByUser.rejected, (state, action) => {
        state.statusByUser = "failed";
        state.error = action.error.message;
      })
      .addCase(deletePressRelease.fulfilled, (state, action) => {
        state.userPressReleases = state?.userPressReleases?.filter(
          (pressRelease) => pressRelease?._id !== action.payload
        );
      });
  },
});

export const SelectPressRelease = (state) => state.pressRelease;
export const selectPressReleaseList = (state) =>
  state.pressRelease.pressReleaseList;
export const selectStatusByCategory = (category) => (state) =>
  state.pressRelease.statusByCategory[category] || "idle";

export const selectUserPressReleases = (state) =>
  state.pressRelease.userPressReleases;
export const selectStatusByUser = (state) => state.pressRelease.statusByUser;

export default pressReleaseFetchSlice;
